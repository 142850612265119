import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigate } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './Form.module.scss';
import { suggestActions } from '../../../actions';
import GuideMessage from '../common/GuideMessage';
import Agreement from '../common/Agreement';

const cx = classNames.bind(styles);
const brands = ['아티제', '쿠차라'];
const roles = ['임대주', '임대주 외 (관리업체, 중개업체 등)'];

class SuggestForm extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    const { isProcessing } = nextProps.suggest;
    if (prevState.isProcessing !== isProcessing) {
      newState.isProcessing = isProcessing;
    }

    // 변경내용 존재하지 않는 경우
    if (Object.keys(newState).length === 0) {
      return null;
    }
    // 변경 내용 존재하는 경우
    else {
      return newState;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedBrand: 0,
      selectedRole: 0,
      isAgree: false,
      isProcessing: props.suggest.isProcessing,
    };

    // Refs.
    this.addressInput = React.createRef();
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
    this.telInput = React.createRef();
    this.contentInput = React.createRef();
    this.attachNameInput = React.createRef();
    this.attachFileInput = React.createRef();
  }

  render() {
    const { selectedBrand, selectedRole, isAgree, isProcessing } = this.state;

    return (
      <div className={cx('contentWrapper')}>
        <Container>
          <Row align="start">
            <Col xs={12} lg={5} offset={{ lg: 1 }} className={cx('column')}>
              <div className={cx('items')}>
                {brands.map((brand, i) => (
                  <div
                    key={i}
                    className={cx('item', { selected: selectedBrand === i })}
                    onClick={this.selectBrand.bind(this, i)}>
                    {selectedBrand === i && <Ionicon icon="md-checkmark" fontSize="17px" className={cx('icon')} />}
                    <div className={cx('text')}>{brand}</div>
                  </div>
                ))}
              </div>
              <div className={cx('items')}>
                {roles.map((role, i) => (
                  <div
                    key={i}
                    className={cx('item', { selected: selectedRole === i })}
                    onClick={this.selectRole.bind(this, i)}>
                    {selectedRole === i && <Ionicon icon="md-checkmark" fontSize="17px" className={cx('icon')} />}
                    <div className={cx('text')}>{role}</div>
                  </div>
                ))}
              </div>
              <input
                type="text"
                ref={this.addressInput}
                placeholder="추천점포주소"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.nameInput}
                placeholder="성명"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.emailInput}
                placeholder="이메일"
                disabled={isProcessing}
                className={cx('field')}
              />
              <input
                type="text"
                ref={this.telInput}
                placeholder="전화번호 ('-' 제외 및 숫자만 입력)"
                disabled={isProcessing}
                className={cx('field')}
              />
              <textarea
                type="text"
                ref={this.contentInput}
                placeholder="내용 (현 업종, 입점시기, 해당층, 전용면적, 희망임차조건 등 세부내용 기재)"
                className={cx('textarea')}
                rows={8}
                disabled={isProcessing}></textarea>
              <div className={cx('attachWrapper')}>
                <label htmlFor="attachFile" className={cx('attachLabel')}>
                  첨부파일
                </label>
                <input
                  type="text"
                  ref={this.attachNameInput}
                  placeholder="예) 입점제안자료, 주요매장사진, 조경도, 도면 등"
                  disabled={true}
                  className={cx('attachName')}
                />
                <input
                  type="file"
                  ref={this.attachFileInput}
                  id="attachFile"
                  onChange={this.attachChange}
                  className={cx('attachFile')}
                />
              </div>
            </Col>
            <Col xs={12} lg={4.5} offset={{ lg: 0.5 }} className={cx('column')}>
              <GuideMessage>
                상담 신청에 대한 회신은 기재해주신
                <br />
                <span className={cx('bold')}>이메일 또는 유선</span>으로 드립니다. (통상 2주 소요)
                <br />
                당사 내부 검토에 따라 회신이 지연될 수 있으며,
                <br />
                주소 또는 이메일을 잘못 입력할 경우
                <br />
                답변이 불가하므로 반드시 확인바랍니다.
              </GuideMessage>
              <Agreement />
              <div className={cx('buttonWrapper')}>
                <div className={cx('checkWrapper')}>
                  <div className={cx('checkBox')} onClick={this.toggleCheck}>
                    {isAgree && <Ionicon icon="md-checkmark" fontSize="17px" />}
                  </div>
                  <div className={cx('agreeText')}>상기 개인정보 수집 및 이용에 동의합니다.</div>
                </div>
                <div className={cx('sendButton')} onClick={this.applyForm}>
                  <div className={cx('text')}>{isProcessing ? '등록 중' : '신청하기'}</div>
                  <Ionicon icon="ios-arrow-forward" fontSize="17px" className={cx('arrow')} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isProcessing: isProcessed } = prevState;
    const { isProcessing, failure } = this.props.suggest;

    if (isProcessed && !isProcessing && !failure) {
      alert('정상적으로 등록이 완료되었습니다.');
      navigate('/'); // 등록 완료 후 홈 이동
    }
  }

  selectBrand = idx => {
    const { isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({
        selectedBrand: idx,
      });
    }
  };

  selectRole = idx => {
    const { isProcessing } = this.state;

    // 등록 중인 아닌 경우
    if (!isProcessing) {
      this.setState({
        selectedRole: idx,
      });
    }
  };

  toggleCheck = () => {
    const { isAgree, isProcessing } = this.state;

    // 등록 중이 아닌 경우
    if (!isProcessing) {
      this.setState({
        isAgree: !isAgree,
      });
    }
  };

  attachChange = e => {
    const attachName = e.target.value.split('\\').pop();
    this.attachNameInput.current.value = attachName;
  };

  applyForm = () => {
    const { selectedBrand, selectedRole, isProcessing } = this.state;

    // 등록 중인 아니고, 입력내용 검증을 통과한 경우
    if (!isProcessing && this.validateForm()) {
      const data = new FormData();
      data.append('brand', brands[selectedBrand]);
      data.append('role', roles[selectedRole]);
      data.append('address', this.addressInput.current.value);
      data.append('name', this.nameInput.current.value);
      data.append('email', this.emailInput.current.value);
      data.append('tel', this.telInput.current.value);
      data.append('content', this.contentInput.current.value);
      data.append('attach', this.attachFileInput.current.files[0]);

      this.props.actions.suggestActions.request(data);
    }
  };

  validateForm = () => {
    const { isAgree } = this.state;

    const address = this.addressInput.current.value.trim();
    const name = this.nameInput.current.value.trim();
    const email = this.emailInput.current.value.trim();
    const tel = this.telInput.current.value.trim();
    const content = this.contentInput.current.value.trim();

    // 추천점포주소
    if (address === '') {
      alert('추천점포주소를 입력해주세요.');
      this.addressInput.current.focus();
      return false;
    }

    // 성명
    if (name === '') {
      alert('성명을 입력해주세요.');
      this.nameInput.current.focus();
      return false;
    }

    // 이메일
    if (email === '') {
      alert('이메일을 입력해주세요.');
      this.emailInput.current.focus();
      return false;
    } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      alert('이메일 형식을 확인해주세요.');
      this.emailInput.current.focus();
      return false;
    }

    // 전화번호
    if (tel === '') {
      alert('전화번호를 입력해주세요.');
      this.telInput.current.focus();
      return false;
    } else if (!/^[0-9]+$/.test(tel)) {
      alert('전화번호 형식을 확인해주세요.');
      this.telInput.current.focus();
      return false;
    }

    // 내용
    if (content === '') {
      alert('내용을 입력해주세요.');
      this.contentInput.current.focus();
      return false;
    }

    // 동의
    if (!isAgree) {
      alert('개인정보 수집 및 이용에 동의해주세요.');
      return false;
    }

    return true;
  };
}

/**
 * Define redux settings
 */
const mapStateToProps = state => {
  const { suggest } = state;
  return {
    suggest,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      suggestActions: bindActionCreators(suggestActions, dispatch),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestForm);
