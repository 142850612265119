import React from 'react';
import classNames from 'classnames/bind';

import styles from './Agreement.module.scss';

const cx = classNames.bind(styles);

const Agreement = () => (
  <div className={cx('container')}>
    수집하는 개인정보는 문의내용 처리 및 고객불만의 해결 등을 위해 사용되며, 관련 법령에 따라 3년간 보관 후 삭제됩니다. 문의접수, 처리 및 회신을 위한 필요 최소한의 개인정보이므로 동의를 해주셔야 해당 서비스를 이용하실 수 있습니다.
  </div>
);

export default Agreement;