import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './GuideMessage.module.scss';

const cx = classNames.bind(styles);

const GuideMessage = ({ children }) => (
  <div className={cx('container')}>{children}</div>
);

GuideMessage.propTypes = {
  children: PropTypes.array.isRequired,
};

export default GuideMessage;