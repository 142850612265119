import React from 'react';
import { ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './Banner.module.scss';

const cx = classNames.bind(styles);

const Banner = () => (
  <ScreenClassRender render={(screenClass) => {
    const isMobile = ['xs'].includes(screenClass);

    return (
      <div className={cx(['container', { mobile: isMobile }])}>
        <div className={cx(['wrapperStyle', { noBr: isMobile }])}>
          <div className={cx('title')}>신규 입점 제의</div>
          <div className={cx(['desc', { noBr: isMobile }])}>
            프리미엄 카페 & 베이커리 <span className={cx('bold')}>아티제</span>의 <span className={cx('bold')}>비지니스 파트너</span>를 모집합니다.<br />
            당사의 모든 매장은 본사에서 직접 운영하는 <span className={cx('bold')}>직영점</span>으로 운영되고 있습니다.<br />
            따라서 개인이 투자, 운영하실 수 있는 <span className={cx('bold')}>가맹점, 체인점 형태는 불가능함</span>을 알려드립니다.
          </div>
        </div>
      </div>
    );
  }} />
);

export default Banner;