import React from 'react';

import Banner from '../../components/Contact/Suggest/Banner';
import Form from '../../components/Contact/Suggest/Form';

const SuggestPage = () => (
  <div>
    <Banner />
    <Form />
  </div>
);

export default SuggestPage;